import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import AgentAbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import { cardContainer } from "@/game-val/components/AbilitySummaryCard.style.jsx";
import { ABILITY_EFFECTS } from "@/game-val/constants/constants-ability-effects.mjs";
import {
  getAbilityKeyFromMeta,
  mapAbilitySlotNameToCasts,
} from "@/game-val/utils.mjs";
import type { AbilityEffectSummary } from "@/game-val/utils/coaching.mjs";
import { summarizeAbilityCasts } from "@/game-val/utils/coaching.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import keyInObject from "@/util/key-in-object.mjs";
import titleCase from "@/util/title-case.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";
import { useScrollForMore } from "@/util/useScrollForMore.jsx";

interface AbilitySummaryCardProps {
  agent: string;
  abilityKillData: Record<
    "ability1Casts" | "ability2Casts" | "grenadeCasts" | "ultimateCasts",
    { kills: number; casts: number }
  >;
  matchId: string;
  baseHeight?: string | number;
  onScroll?: () => void;
}

function AbilitySummaryCard(props: AbilitySummaryCardProps) {
  const { abilityKillData, agent, matchId, baseHeight = 362, onScroll } = props;
  const { t } = useTranslation();
  const { val } = useSnapshot(readState);

  const extraStats = val.coachingData?.[matchId]?.extraStats;

  const agentData = val?.cms?.agents;

  const summarizedCasts = useMemo(() => {
    if (!extraStats) return null;

    const abilityCasts = extraStats?.abilityCasts;
    if (!abilityCasts || abilityCasts?.length < 1) return null;

    return summarizeAbilityCasts(abilityCasts);
  }, [extraStats]);

  const cmsAgent = agentData.find((a) => a.key === agent);
  const abilityData = cmsAgent?.abilities || [];

  const { containerRef, contentRef, scrollTextComponent } = useScrollForMore();

  // Missing required data, return nothing
  if (!extraStats || !summarizedCasts) return null;

  return (
    <Card padding="0">
      <div
        className={cardContainer}
        ref={containerRef}
        style={{
          "--base-height":
            typeof baseHeight === "number" ? `${baseHeight}px` : baseHeight,
        }}
        tabIndex={0}
        onScroll={onScroll}
      >
        <div className="content" ref={contentRef}>
          <header>
            <h5 className="type-large-title--bold">
              {t("common:abilities", "Abilities")}
            </h5>
          </header>

          {Object.entries(summarizedCasts).map(([key, value]) => {
            const abilitySlot = key as keyof AbilityEffectSummary;
            const summary = value as AbilityEffectSummary[typeof abilitySlot];
            const abilityInfo = getAbilityKeyFromMeta(abilitySlot, abilityData);
            const abilityKey = abilityInfo?.key;

            if (!abilityInfo) return null;

            return (
              <div key={abilityKey} className="ability">
                <div className="ability-meta">
                  <AgentAbilityIcon
                    className="ability-icon"
                    ability={abilityInfo}
                  />
                  <span className="type-subtitle--bold">
                    {abilityInfo.name}
                  </span>
                </div>
                <div className="ability-stat">
                  <span className="type-callout">
                    {titleCase(t("common:casts", "Casts"))}
                  </span>
                  <span className="type-callout">
                    {abilityKillData?.[
                      mapAbilitySlotNameToCasts(abilitySlot)
                    ].casts.toLocaleString(getLocale(), {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })}
                  </span>
                </div>
                {Object.entries<number>(summary).map(([eff, val]) => {
                  const effect = keyInObject(ABILITY_EFFECTS, eff)
                    ? ABILITY_EFFECTS[eff]
                    : null;

                  const formatOptions = effect?.formatOptions ?? {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  };

                  if (
                    !effect ||
                    effect?.hidden === true ||
                    effect?.hidden?.[abilityKey]
                  ) {
                    return null;
                  }

                  return (
                    <div className="ability-stat" key={eff}>
                      <span className="type-callout">{t(...effect.label)}</span>
                      <span className="type-callout">
                        {val.toLocaleString(getLocale(), formatOptions)}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {scrollTextComponent}
    </Card>
  );
}

export default AbilitySummaryCard;
