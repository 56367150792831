import type { DeepReadonly } from "ts-essentials";

import type { ValorantRankBenchmark } from "@/data-models/valorant-coaching-benchmark-data.mjs";
import type { ValorantInternalMatch } from "@/data-models/valorant-internal-match-data.mjs";
import { ARMOR_TYPES, SCORE_BREAKPOINTS } from "@/game-val/constants.mjs";
import type {
  CoachingCategory,
  PlayerData,
  PopulatedCoachingCategory,
} from "@/game-val/constants/coaching-constants.mjs";
import {
  FULL_BUY_WEAPONS,
  MAX_STATS_PER_CATEGORY,
  ROUNDS_PER_HALF,
} from "@/game-val/constants/coaching-constants.mjs";
import type { AgentAbilityEffects } from "@/game-val/constants/constants-ability-effects.mjs";
import clone from "@/util/clone.mjs";
import { statPerformance } from "@/util/coaching.mjs";
import { devWarn } from "@/util/dev.mjs";
import isNonNullable from "@/util/is-non-nullable.mjs";
import keyInObject from "@/util/key-in-object.mjs";
import rangeBucket from "@/util/range-bucket.mjs";

export interface CoachingPerformance {
  loading: boolean;
  tooShort: boolean;
  hasAbilities: boolean;
  hasEcon: boolean;
}

export interface AbilityEffect {
  stat: AgentAbilityEffects;
  value: number;
}

export interface AbilityCast {
  castTime: number;
  effects: AbilityEffect[];
  round: number;
  slot: number;
}

export type AbilitySummary = PartialRecord<AgentAbilityEffects, number>;

export interface AbilityEffectSummary {
  abilityOneEffects: AbilitySummary;
  abilityTwoEffects: AbilitySummary;
  grenadeEffects: AbilitySummary;
  ultimateEffects: AbilitySummary;
}

export function isCoachingEnabledForQueue(queue: string) {
  return queueHasEcon(queue);
}

export function queueHasEcon(queue: string) {
  return ["competitive", "swiftplay", "premier", "unrated"].includes(queue);
}

export function queueHasAbilities(queue: string) {
  return [
    "competitive",
    "swiftplay",
    "premier",
    "unrated",
    "spikerush",
  ].includes(queue);
}

interface CalculateCategoryParams {
  category: CoachingCategory;
  playerData: PlayerData;
  benchmarkData: ValorantRankBenchmark;
}

/**
 * Calculates performance scores for each stat in the category, and returns the populated
 * category object. It will only
 */
export function calculateCategory(
  params: CalculateCategoryParams,
): PopulatedCoachingCategory {
  const { category: originalCategory, playerData, benchmarkData } = params;

  // Copy here so we don't overwrite the original category
  const category = clone(originalCategory);

  const mappedStats = category.stats
    .map((stat) => {
      if (!stat) {
        devWarn(
          `Attempted to calculate an empty stat. Check the definition of ${category.title[0]}`,
        );
        return null;
      }

      const { lowerIsBetter } = stat;
      const playerVal = stat?.getPlayerVal?.(playerData);
      const benchmark = stat?.getBenchmark?.(benchmarkData);
      if (!playerVal || !benchmark || Number.isNaN(playerVal)) {
        devWarn(
          `Unable to calculate stat ${
            stat.label?.[1] ?? stat.label
          }. Data was missing or invalid.`,
          {
            playerVal,
            benchmark,
          },
        );
        return null; // No way to calculate this stat, return null.
      }

      stat.performance = statPerformance({
        playerVal,
        benchmarkMean: benchmark.avg,
        benchmarkStdev: benchmark.stddev,
        lowerIsBetter,
        lowerBoundStdevMultiplier: 1.5,
        upperBoundStdevMultiplier: 1.4, // Lower upper bound slightly to shift the median score upwards
        ...stat.performanceOverrides,
      });

      return stat;
    })
    .filter(isNonNullable)
    .slice(0, MAX_STATS_PER_CATEGORY); // Take the first stats up until the limit of MAX_STATS_PER_CATEGORY

  const scoreSum = mappedStats.reduce(
    (sum, stat) => sum + (stat?.performance?.score ?? 0),
    0,
  );

  category.score = scoreSum / mappedStats.length;
  category.stats = mappedStats;

  return category as PopulatedCoachingCategory;
}

/**
 * A helper for building a forEach callback that will write each passed ability effect
 * to a record summarizing the total performance.
 *
 * @param target The object to write the effect summary to
 */
function writeAbilityEffects(
  target: PartialRecord<AgentAbilityEffects, number>,
) {
  // No target to write to, nothing to do
  if (!target) return () => {};

  return ({ stat, value }: AbilityEffect) => {
    if (!(stat in target)) {
      target[stat] = value;
    } else {
      target[stat] += value;
    }
  };
}

/**
 * This function takes in a list of ability casts throughout a whole match, and returns an object with each
 * ability's effects mapped into a record with the sum total of their corresponding values.
 *
 * @param abilityCasts Ability casts sent from the postMatch event
 * @returns A summarized record of each ability's effects over the course of the game
 */
export function summarizeAbilityCasts(
  abilityCasts: DeepReadonly<AbilityCast[]>,
): AbilityEffectSummary {
  return abilityCasts.reduce(
    (acc, cast) => {
      // No effects to process, continue
      if (cast.effects.length === 0) return acc;

      let abilityWriter: ((val: AbilityEffect) => void) | null;
      switch (cast.slot) {
        case 3:
          abilityWriter = writeAbilityEffects(acc.grenadeEffects);
          break;
        case 4:
          abilityWriter = writeAbilityEffects(acc.abilityOneEffects);
          break;
        case 5:
          abilityWriter = writeAbilityEffects(acc.abilityTwoEffects);
          break;
        case 9:
          abilityWriter = writeAbilityEffects(acc.ultimateEffects);
          break;
        default:
          abilityWriter = null;
          devWarn(
            `Unknown valorant ability slot ${cast.slot}, not writing to accumulator`,
          );
          break;
      }

      if (typeof abilityWriter === "function")
        cast.effects.forEach(abilityWriter);

      return acc;
    },
    {
      abilityOneEffects: {} as AbilitySummary,
      abilityTwoEffects: {} as AbilitySummary,
      grenadeEffects: {} as AbilitySummary,
      ultimateEffects: {} as AbilitySummary,
    },
  );
}

type PlayerEconomy =
  ValorantInternalMatch["roundResults"][number]["playerEconomies"][number];

/**
 * Returns true if the player has a weapon that costs >= 2900 credits
 *
 * @param econ The player's economy from the raw valorant match data.
 * @returns True if the weapon is a full-buy weapon.
 */
export function hasFullBuyWeapon(econ: PlayerEconomy) {
  return FULL_BUY_WEAPONS.includes(econ.weapon);
}

/**
 * Takes in a player's economy result, and checks if the player bought a gun
 * worth >= 2900, and has heavy armor.
 *
 * @param econ The player's economy from the raw valorant match data.
 * @returns If the round is considered a full buy
 */
export function isFullBuyRound(econ: PlayerEconomy) {
  return hasFullBuyWeapon(econ) && econ.armor === ARMOR_TYPES.heavy;
}

/**
 * Takes in a round number and the queue type to determine if the round is a pistol round.
 * @param roundNum The current round number
 * @param queue The queue to determine if it is a pistol round for.
 */
export function isPistolRound(roundNum: number, queue: string) {
  const roundsPerHalf = keyInObject(ROUNDS_PER_HALF, queue)
    ? ROUNDS_PER_HALF[queue]
    : 12;
  // First rounds of each half, except sudden death/overtime
  return (roundNum - 1) % roundsPerHalf === 0 && roundNum < roundsPerHalf * 2;
}

/**
 * Takes in a round number and the queue type to determine if a round is full-buyable.
 * Generally this means not the first two rounds of either half, except in swiftplay, in
 * which only the first round is impossible to full buy on, as subsequent rounds give at
 * least 2400 credits.
 *
 * @param roundNum The current round number
 * @param queue The queue to determine if the round is full buyable for.
 */
export function isFullBuyableRound(roundNum: number, queue: string) {
  // Swiftplay provides +2400 credits on round 2, and +600 more if team won prev round.
  if (queue === "swiftplay") return !isPistolRound(roundNum, queue);
  return !(
    isPistolRound(roundNum, queue) || isPistolRound(roundNum - 1, queue)
  );
}

const scoreBuckets = rangeBucket<Translation>([
  [0, ["common:score.needsWork", "Needs Work"]],
  [SCORE_BREAKPOINTS.bot, ["common:score.fair", "Fair"]],
  [SCORE_BREAKPOINTS.mid, ["common:score.good", "Good"]],
  [SCORE_BREAKPOINTS.top, ["common:score.excellent", "Excellent"]],
]);

/**
 * Returns a label based on the player's coaching score.
 *
 * @param score A score between 0 and 1
 * @returns The label for the score
 */
export function scoreLabel(score: number): Translation {
  return scoreBuckets[score];
}

/**
 * Returns a CSS color based on the score's value
 *
 * @param score A score between 0 and 1
 * @returns The CSS-useable color for the score
 */
export function performanceColor(score: number) {
  const buckets = rangeBucket([
    [0, "var(--red)"],
    [SCORE_BREAKPOINTS.bot, "var(--perf-neutral)"],
    [SCORE_BREAKPOINTS.top, "var(--green)"],
  ]);

  return buckets[score];
}
